<template>
  <div
    class="program-card"
    @click="$emit('click')"
  >
    <ImageLazy
      :src="imgUrl"
      :alt="alt"
      @error="(e) => setImageDefault(e, 'program-card')"
    />
    <h4>
      {{ title }}
    </h4>
  </div>
</template>
<script>
import ImageLazy from '@/components/ImageLazy/index.vue'
import { setImageDefault } from "@/components/ImageLazy/util";

export default {
  name: "ProgramPromoCard",
  components: { ImageLazy },
  props: {
    imgUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      setImageDefault
    }
  },
}
</script>
<style lang="scss" scoped>
@import "./program-card.scss";
</style>
