<template>
  <section class="program-section">
    <div class="program-section__breadcrumb">
      <BreadCrumb
        class="top-left"
        custom-title-id="breadcrumb-title"
        :is-use-route-name="true"
      />
    </div>
    <div class="program-section__banner do-flex">
      <div class="program-section__banner__text do-flex-col">
        <h1 v-html="'Promo Ngga \n Habis-Habis!'" />
        <h2>Bikin Anda Untung Berlapis-Lapis</h2>
      </div>
      <ImageLazy
        :src="decideBannerImage"
        loading="lazy"
        alt="program-banner"
        @error="(e) => setImageDefault(e, 'program-banner')"
      />
    </div>
    <div
      v-if="!isLoading && paginatedData"
      class="program-section__content"
    >
      <ProductCard
        v-for="(program, i) of paginatedData"
        :key="i"
        :img-url="(program.attachment_program_subphoto_url || program.attachment_program_photo_url)"
        :title="program.program_name"
        :alt="(program.alt_program_subphoto_url || program.alt_program_photo_url)"
        @click="() => redirectToDetailPromo(program.program_slug)"
      />
    </div>
    <div
      v-if="isLoading"
      class="program-section__content"
    >
      <Shimmer
        v-for="i in 9"
        :key="i"
      />
    </div>
    <div class="program-section__pagination">
      <Pagination
        v-model="page"
        :total-all-item="programDataLength"
        :per-page="perPage"
        @next="nextPage"
        @prev="prevPage"
      />
    </div>
  </section>
</template>
<script>
import mixinMetaInfo from '@/misc/mixinMetaInfo';
import mixinMobile from '@/misc/mixinMobile';
import BreadCrumb from '@/components/new-breadcrumb/BreadCrumb.vue';
import Pagination from '@/components/new-pagination/Pagination.vue';
import ProductCard from './components/ProgramCard.vue';
import PromoUseCase from './app/usecase/promo';
import Shimmer from '@/components/shimmer/index.vue';
import ImageLazy from '@/components/ImageLazy/index.vue';
import { setImageDefault } from '@/components/ImageLazy/util';
import { mapGetters } from 'vuex';

export default {
  name: 'Promo',
  components: {
    BreadCrumb,
    Pagination,
    ProductCard,
    Shimmer,
    ImageLazy,
  },
  mixins: [mixinMobile, mixinMetaInfo],
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
  data() {
    return {
      searchMetaInfo: '_promo', // from mixinMetaInfo, path tapi / dengan _ ex: /about jadi _about
      isLoading: false,
      page: 1,
      perPage: 9,
      metaInfo: '',
      setImageDefault,
    };
  },
  computed: {
    ...mapGetters(['PROMOS']),
    isDesktopSize() {
      return this.windowWidth > 599;
    },
    programDataLength() {
      return this.PROMOS.data && this.PROMOS.data.length || 0;
    },
    paginatedData() {
      return this.PROMOS.data
         && this.PROMOS.data.slice(
           (this.page - 1) * this.perPage,
           this.page * this.perPage,
         );
    },
    decideBannerImage() {
      return this.isDesktopSize ? '/images/promo/banner.webp' : '/images/promo/banner_mobile.webp';
    },
  },
  mounted() {
    document.querySelector('.title-breadcrumb').innerHTML = 'Promo';
    this.fetchProgram();
    this.handleMobilePagination();
    window.addEventListener('resize', this.handleMobilePagination);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleMobilePagination);
  },
  methods: {
    async fetchProgram() {
      try {
        this.isLoading = true;
        const res = await PromoUseCase.getAll();
        this.$store.dispatch('setPromos', res);
        window.scrollTo({ top: 0, left: 0 });
      } catch (err) {
        this.$store.dispatch('setPromos', {});
      } finally {
        this.isLoading = false;
      }
    },
    nextPage() {
      this.page = this.page + 1;
      window.scrollTo({ top: 0, left: 0 });
    },
    prevPage() {
      if (this.page === 1) return;
      this.page = this.page - 1;
      window.scrollTo({ top: 0, left: 0 });
    },
    redirectToDetailPromo(slug) {
      this.$router.push(`/promo/detail/${slug}`);
    },
    handleMobilePagination() {
      if (this.isDesktopSize) {
        document.querySelector('.pagination-desc').style.display = 'block';
      } else {
        document.querySelector('.pagination-desc').style.display = 'none';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./program.scss";
</style>
