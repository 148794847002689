import PromoRepository from '../api/program';
import '../types';
import { TypeStatus } from '../enum';
import { TimeMapper } from '@/misc/timeMapper';

class PromoUseCase {
  constructor() {
    /** @type {PromoRepository} */
    this.api = new PromoRepository();
  }

  /**
   * sort by active and active date
   * @return {Promise<{data: Promo[], err: Error}>}
   */
  async getAll(page = 1) {
    const t = new Date();
    const qDate = TimeMapper.SetFormatDate(t.toDateString(), false, ['-', '-', '-'], false, false).split('-').reverse().join('-');

    const query = `page=${page}&s=active&order_by=active_date&date=${qDate}`;

    const resp = await this.api.getAll(query);
    if (resp.data.length > 0) {
      const todayDate = new Date(new Date().toDateString());
      resp.data = resp.data.filter((v) => {
        const active_date = new Date(new Date(v.active_date).toDateString());
        const expiry_date = new Date(new Date(v.expiry_date).toDateString());
        return v.status === TypeStatus.ACTIVE
          && active_date <= todayDate
          && expiry_date >= todayDate;
      });
      resp.data.sort((a, b) => new Date(b.active_date) - new Date(a.active_date));
    }
    return resp;
  }

  async getDetail(slug) {
    const { data, err } = await this.api.getDetail(slug);
    return { data, err };
  }
}

export default new PromoUseCase();
