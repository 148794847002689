<template>
  <div
    class="my-shimmer"
    :style="`height: ${height}%;`"
  >
    <div
      class="body-my-shimmer"
      :style="`height: ${height}px;`"
    />
  </div>
</template>

<script>
export default {
  props: {
    height: {
      default: '100',
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.css";
</style>
