import InternalAPI from '@/app/baseAxios';
import '../types';

/**
 * @borrows InternalAPI
*/
export default class PromoRepository extends InternalAPI {
  constructor() {
    super();
    /**
     *
     * @type {string}
     */
    this.programListEndpoint = '/v1/program/data';
    this.programDetailEndpoint = '/v1/program/slug/';
  }

  /**
   * @param {string} query
   * @return {Promise<{data: Promo[], err: Error}>}
   */
  async getAll(query) {
    try {
      /**
       *
       * @type {AxiosResponse<Object>}
       */
      const res = await this.$axios.get(`${this.programListEndpoint}?${query}`);
      if (res.status === 200 && res.data) {
        return { data: res.data.data, err: null };
      }
      return { data: [], err: res.data.data };
    } catch (err) {
      let newErr = err.message;
      if (err.response && err.response.data.message) {
        newErr = err.response.data.message;
      } else if (!err.response || !err.response.data) {
        newErr = err.message;
      }
      return { data: [], err: this.setErrorMessage(newErr) };
    }
  }

  /**
   * @param {string} slug
   * @return {Promise<{data: Promo, err: Error}>}
   */
  async getDetail(slug) {
    try {
      /**
       *
       * @type {AxiosResponse<Object>}
       */
      const res = await this.$axios.get(this.programDetailEndpoint + slug);
      if (res.status === 200 && res.data) {
        return { data: res.data, err: null };
      }
      return { data: {}, err: res.data };
    } catch (error) {
      return { data: [], err: this.setErrorMessage(error.response.data) };
    }
  }
}
