<template>
  <section
    v-if="PROMO && PROMO.status === 'ACTIVE'"
    class="program-detail-section"
  >
    <div class="program-detail-section__breadcrumb">
      <BreadCrumb />
    </div>
    <section class="program-detail-section__container">
      <section
        v-if="!isLoading || PROMO"
        class="program-detail-section__container__content"
      >
        <!-- content -->
        <ImageLazy
          class="program-detail-section__container__content__banner"
          :src="PROMO.attachment_program_photo_url"
          :alt="PROMO.alt_program_photo_url"
          @error="(e) => setImageDefault(e, 'program-banner-detail')"
        />
        <h1 class="program-detail-section__container__content__title">
          {{ PROMO.program_name }}
        </h1>
        <div class="program-detail-section__container__content__period">
          <SocialMediaShare :data="SocialMediaShareContentData" />
        </div>
        <div class="program-detail-section__container__content__text ">
          <h1>Deskripsi</h1>
          <div
            class="program-detail-section__container__content__text__content"
            v-html="PROMO.program_description"
          />
        </div>
        <div class="program-detail-section__container__content__text ">
          <h1>Syarat & Ketentuan</h1>
          <div
            class="program-detail-section__container__content__text__content"
            v-html="PROMO.program_term_condition"
          />
        </div>
      </section>
      <section
        v-else
        class="program-detail-section__container__content"
      >
        <div class="program-detail-section__container__content__skeleton">
          <div class="skeleton-box program-detail-section__container__content__skeleton__image-content" />
          <div>
            <div class="skeleton-box" />
            <div class="skeleton-box social-media" />
          </div>
          <div class="skeleton-box" />
          <div class="skeleton-box" />
          <div class="skeleton-box" />
          <div class="skeleton-box" />
          <div class="skeleton-box" />
        </div>
      </section>
      <section class="program-detail-section__container__program-list">
        <div class="program-detail-section__container__program-list__head">
          <h1>Promo Lainnya</h1>
          <p @click="$router.push('/promo')">
            Semua Promo
          </p>
        </div>
        <div
          v-if="!isLoadingProgram && ProgramListData.data"
          class="program-detail-section__container__program-list__content"
        >
          <ProgramCard
            v-for="(program, i) of ProgramListData.data"
            :key="i"
            :img-url="(program.attachment_program_subphoto_url || program.attachment_program_photo_url)"
            :title="program.program_name"            
            :alt="(program.alt_program_subphoto_url || program.alt_program_photo_url)"
            @click="() => redirectToDetailPromo(program.program_slug)"
          />
        </div>
        <div
          v-if="isLoadingProgram"
          class="program-detail-section__container__program-list__content"
        >
          <Shimmer
            v-for="i in 4"
            :key="i"
            style="margin-bottom: 10px;"
          />
        </div>
      </section>
    </section>
  </section>
  <section
    v-else-if="isLoading"
    class="promo-loader"
  >
    <Loader color-class="l-grey" />
  </section>
  <section v-else>
    <NotFound v-if="!isLoading" />
  </section>
</template>
<script>
import BreadCrumb from '@/components/new-breadcrumb/BreadCrumb.vue';
import SocialMediaShare from '@/components/social-media-share/SocialMediaShare.vue';
import ProgramCard from '../components/ProgramCard.vue';
import PromoUseCase from '../app/usecase/promo';
import Shimmer from '@/components/shimmer/index.vue';
import ImageLazy from '../../../components/ImageLazy/index.vue';
import { setImageDefault } from '@/components/ImageLazy/util';
import NotFound from '@/pages/not-found/NotFound.vue';
import Loader from '@/components/new-loader/Loader';
import defaultSso from '@/helpers/SEODefault'
import { mapGetters } from 'vuex';

export default {
  name: 'DetailPromo',
  components: {
    BreadCrumb,
    SocialMediaShare,
    ProgramCard,
    Shimmer,
    ImageLazy,
    NotFound,
    Loader,
  },
  metaInfo() {
    if (JSON.stringify(this.PROMO) === '{}') {
      return this.SEO({
        channel: 'article',
        ...defaultSso
      })
    }
    return this.SEO({
      channel: 'article',
      title: this.PROMO?.program_name,
      description: this.PROMO?.meta_info_description || `${this.PROMO?.program_name} | ` + `Periode Promo | ${this.PROMO?.active_date} - ${this.PROMO?.expiry_date}`,
      keywords: this.PROMO?.meta_info_keyword || `${this.PROMO?.program_name?.toLowerCase().split(' & ').join(' ').split(', ')
        .join(' ')
        .split(' ')
        .join(', ')}, ` + `periode promo | ${this.PROMO?.active_date?.toLowerCase()} - ${this.PROMO?.expiry_date?.toLowerCase()}`,
      publishedTime: this.PROMO?.created_at,
      image: this.PROMO?.attachment_program_photo_url,
    });
  },
  data() {
    return {
      isLoading: true,
      isLoadingProgram: false,
      ProgramListData: {},
      SocialMediaShareContentData: {
        url: new URL(!this.isSsr() ? location.href : `http://${process.env.DEV_HOST}:${process.env.APP_PORT}`).href,
        title: '',
        description: '',
        quote: '',
        hashtags: '',
      },
      setImageDefault,
    };
  },
  async asyncData({ route, store }) {
    try {
      const res = await PromoUseCase.getDetail(route.params.program_slug);
      const todayDate = new Date(new Date().toDateString());
      const active_date = new Date(new Date(res.data.active_date).toDateString());
      const expiry_date = new Date(new Date(res.data.expiry_date).toDateString());
      if (
        res.data
          && res.data.status == 'ACTIVE'
          && active_date <= todayDate
          && expiry_date >= todayDate
      ) {
        store.dispatch('setPromo', res.data);
      } else {
        store.dispatch('setPromo', {});
      }
    } catch (error) {
      store.dispatch('setPromo', {});
    }
  },
  computed: {
    ...mapGetters(['PROMO'])
  },
  watch: {
    '$route.path': {
      handler() {
        this.fetchProgramDetail();
        this.fetchProgramList();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchProgramDetail();
    this.fetchProgramList();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  },
  methods: {
    async fetchProgramDetail() {
      try {
        this.isLoading = true;
        const res = await PromoUseCase.getDetail(this.$route.params.program_slug);
        const todayDate = new Date(new Date().toDateString());
        const active_date = new Date(new Date(res.data.active_date).toDateString());
        const expiry_date = new Date(new Date(res.data.expiry_date).toDateString());
        if (
          res.data
            && res.data.status == 'ACTIVE'
            && active_date <= todayDate
            && expiry_date >= todayDate
        ) {
          this.$store.dispatch('setPromo', res.data);
          this.SocialMediaShareContentData.url = new URL(location.href).href;
          const copyUrl = this.SocialMediaShareContentData.url;
          this.SocialMediaShareContentData = {
            ...this.SocialMediaShareContentData,
            title: res.data.program_name,
            hashtags: 'lionparcel',
            copyUrl,
          };
        } else {
          this.$store.dispatch('setPromo', {});
        }
      } catch (error) {
        this.$store.dispatch('setPromo', {});
      } finally {
        this.isLoading = false;
      }
    },
    async fetchProgramList() {
      try {
        this.isLoadingProgram = true;
        const res = await PromoUseCase.getAll();
        res.data = res.data.filter(program => program.program_slug !== this.$route.params.program_slug).slice(0, 4);
        this.ProgramListData = res;
      } catch (error) {
        this.ProgramListData = {};
      } finally {
        this.isLoadingProgram = false;
      }
    },
    redirectToDetailPromo(slug) {
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      this.$router.push(`/promo/detail/${slug}`);
      this.fetchProgramDetail();
      this.fetchProgramList();
    },
  },
};
</script>

<style scoped lang="scss">
@import "./style.scss";

.promo-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  & ::v-deep .lds-ellipsis {
    @include for-size(mobile) {
      left: 42% !important;
    }
  }
}

::v-deep iframe {
  @include for-size(mobile) {
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
  }
}

</style>
<style lang="scss">
  .program-detail-section__container__content__text__content {
    p {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
    }
    ol {
      display: block;
      list-style-type: decimal;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
}
    a, p a{
      font-size: unset!important;
      color:#3273dc;
    }
    ul{
      list-style:revert;
      margin-block-start: auto;
      margin-block-end: auto;
      margin-inline-start: auto;
      margin-inline-end: auto;
      padding-inline-start:auto;
      margin:revert;
      padding:revert;
    }
    ul li{
      list-style:revert;
      margin-block-start: auto;
      margin-block-end: auto;
      margin-inline-start: auto;
      margin-inline-end: auto;
      padding-inline-start:auto;
      margin:revert;
      padding:revert;
    }
  }
</style>
